<template>
  <div>
    <div class="title">消息中心</div>
    <div class="content">
      <div class="flex">
        <el-date-picker
          clearable
          style="width: 400px"
          v-model="value1"
          type="daterange"
          range-separator="至"
          start-placeholder="请选择开始时间"
          end-placeholder="请选择结束时间"
        >
        </el-date-picker>
        <el-input
          class="input"
          v-model="input"
          placeholder="请输入消息关键字"
        ></el-input>
        <div class="btn">搜索</div>
      </div>
      <div style="text-align: center">
        <img class="img" src="../../assets/kong_yemian_imgs.png" alt="" />
      </div>
      <div class="txt">暂无内容</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value1: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
}
.content {
  margin-top: 14px;
  padding: 40px 40px 500px 40px;
  box-sizing: border-box;
  background-color: #fff;
  .input {
    width: 320px;
    margin-left: 22px;
  }
  .btn {
    cursor: pointer;
    margin-left: 24px;
    width: 93px;
    height: 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7c7c7c;
    background-color: #edf5fe;
    text-align: center;
    line-height: 40px;
    border: 1px solid #d8e0ea;
  }
  .img {
    margin-top: 30px;
  }
  .txt {
    margin-top: 20px;
    text-align: center;
    font-size: 18px;
    color: #999;
  }
}
</style>
